import { FETCH_ALL_LOCATION_NAME_EVSEID, FETCH_BOOKING_PERCENTAGE, FETCH_BOOKING_COUNT, STATION_URL, CREATE_NEW_STATION_V2_API, UPDATE_NEW_STATION_V2_API, FETCH_SINGLE_EVSE_V2_API, SAVE_EVSE_API, FETCH_ALL_EVSE_API, FETCH_EVSE_SUB_NETWORK_API, FETCH_EVSE_NETWORK_API, TRIGGER_STATION_EVSE_STATUS_API, LOAD_OCPP_NOTI_API, FETCH_SINGLE_EVSE_API, UPDATE_EVSE_API, LOAD_BOOTNOTIFICATION_API, ACTIVATE_STATION, DEACTIVATE_STATION, FETCH_LIVE_CHARGING_API, CHARGING_SERVICE_URL, FETCH_ALL_PARKING_TYPE_API, FETCH_ALL_CONNECTOR_TYPE_API, FETCH_ALL_POWER_RANGE_API, FETCH_ALL_CHARGER_STATUS_API, FETCH_CUSTOMER_BOOKING_DATA, FETCH_CHARGER_BOOKING_SLOTS, SAVE_CHARGER_MASTER_SLOTS, FETCH_LIVE_CHARGING_GRAPH_API, EVSE_PERCENTAGE_CHANGE_API, TIME_MASTER_API, FETCH_ALL_EVSEID, SAVE_ROAMING_STATIONS_API, FETCH_EMAIL_DURATION_API, SAVE_CONFIGURED_API, FETCH_SINGLE_EMAIL_CONFIG_API, FETCH_PREVIOUS_EMAIL_CONFIG_API, COMMAND_URL, DELETE_API_BOOKING, FETCH_EVSE_ID_API, UPDATE_BOOKING_DATA_API, DELETE_BOOKING_API, SAVE_STATION_GROUP_API, FETCH_ALL_STATION_GROUP_API, FETCH_SINGLE_STATION_GROUP_API, UPDATE_STATION_GROUP_API, ACTIVATE_STATION_GROUP_API, DEACTIVATE_STATION_GROUP_API, SAVE_STATION_GROUP_WITH_TARIFF_API, ACTIVATE_STATION_GROUP_WITH_TARIFF_API, DEACTIVATE_STATION_GROUP_WITH_TARIFF_API, FETCH_SINGLE_STATION_GROUP_WITH_TARIFF, FETCH_ALL_STATION_GROUP_WITH_TARIFF, UPDATE_STATION_GROUP_WITH_TARIFF, ALL_DAYS_API, FETCH_BOOKING_CHARGER_SLOTS_API, STATION_DELETE_API, CLEARED_CACHE_API_1, CLEARED_CACHE_API_2, CLEARED_CACHE_API } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";

export const DeleteBookingAPI = (reserveid) => {
  // console.log("reserveid from action",reserveid)
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + DELETE_API_BOOKING + "?reservationid=" + reserveid, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      // mode:'no-cors',

    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'BOOKING_DELETE_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const allEvseId = (evse_id) => {
  // console.log("FETCH_EVSEID",evse_id)
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_ALL_EVSEID + "?name=" + evse_id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_EVSEID', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const allLocationAndEvseId = (name, evse_id) => {
  // console.log("LOCATION FROM EVSE",name)
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_ALL_LOCATION_NAME_EVSEID + "?name=" + name, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_LOCATION_NAME_AND_EVSEID', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchBookingPercentage = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_BOOKING_PERCENTAGE + "?role=" + role + "&organisation=" + organisation, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }

    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_BOOKING_PERCENTAGE', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchBookingCount = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_BOOKING_COUNT + "?organisation=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_BOOKING_DATA', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const saveEvseAction = (data, amenity) => {
  const { location, address, city, country, state, Zipcode, latitude, longitude, publish, parkingtype, status, operator, network, category, evses, qty } = data
  const coordinates = { 'latitude': latitude, 'longitude': longitude }
  const operatorValue = { 'name': operator }
  let publistvalue = publish == "true" ? true : false
  const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + SAVE_EVSE_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "name": location,
        'address': address,
        'city': city,
        "state": state,
        "country": country,
        "postal_code": Zipcode,
        "coordinates": coordinates,
        "publish": true,
        "parking_type": parkingtype,
        "status": status,
        "evses": evses,
        "role": role,
        "amenities": amenity,
        "operator": operatorValue,
        "location_type": network,
        "location_sub_type": category,
        "charger_qty": qty,
        "organisation": organisation

      })
    });
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'SAVE_EVSE', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const allEvseAction = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_ALL_EVSE_API + "?role=" + role + "&organisation=" + organisation, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_EVSE_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchBookingSlot = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  // console.log(token)
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    // console.log(STATION_URL + FETCH_BOOKING_CHARGER_SLOTS_API + "?operator=" + operator)
    const data = await ApiHandler(STATION_URL + FETCH_BOOKING_CHARGER_SLOTS_API + "?organisation=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_EVSE_ID', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const DeleteBookingCharger = (evse_id) => {
  // console.log("evse_id from action", evse_id)
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + DELETE_BOOKING_API + "?evse_id=" + evse_id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'DELETE_BOOKING_CHARGER_SLOTS', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const updateBookingCharger = (data, selectedEvseIdOperator) => {
  // console.log("selectedEvseIdOperator",selectedEvseIdOperator)
  const { regular_hours, evse_id } = data
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + UPDATE_BOOKING_DATA_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "role": role,
        "operator": selectedEvseIdOperator,
        'evse_id': evse_id,
        'regular_hours': regular_hours,
      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'UPDATE_BOOKING_DATA', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetSubNetworkAction = (nt) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_EVSE_SUB_NETWORK_API + "?network=" + nt, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_SUB_NETWORK', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetNetworkAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_EVSE_NETWORK_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_NETWORK', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchStationStatusDetailAction = (status, currentPage, pageSize) => {
  // console.log("currentPage action", currentPage)
  // console.log("pageSize action", pageSize);
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const role1 = role === "Admin" ? 'All' : role;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    // console.log(STATION_URL + TRIGGER_STATION_EVSE_STATUS_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&role=" + role1 + "&status=" + status + "&page=" + currentPage + "&limit=" + pageSize)
    const data = await ApiHandler(STATION_URL + TRIGGER_STATION_EVSE_STATUS_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&role=" + role1 + "&status=" + status + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_STATION_EVSE_STATUS_ALL', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchAllStationStatusDetailAction = (status, currentPage, pageSize) => {
  // console.log("currentPage action", currentPage)
  // console.log("pageSize action", pageSize);
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const role1 = role === "Admin" ? 'All' : role;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + TRIGGER_STATION_EVSE_STATUS_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&role=" + role1 + "&status=" + status + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_ALL_STATION_STATUS', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchOCPPNotificationStatusDetailAction = (page, limit) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(COMMAND_URL + LOAD_OCPP_NOTI_API + "?page=" + page + "&limit=" + limit, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_OCPP_NOTI_DATA', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchAllOcppNotificationStatusDetailAction = (page, limit) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(COMMAND_URL + LOAD_OCPP_NOTI_API + "?page=" + page + "&limit=" + limit, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_ALL_OCPP_NOTIFICATION_STATUS_DETAILS', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchEmailDuration = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_EMAIL_DURATION_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_EMAIL_DURATION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchEmailCofig = () => {
  const { token, userid } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_PREVIOUS_EMAIL_CONFIG_API + "?userid=" + userid, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_EMAIL_CONFIG_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchEvseStationAction = (stationid) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_SINGLE_EVSE_API + "?stationid=" + stationid, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_EVSE_SINGLE', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const updateEvseAction = (sid, data, amenity) => {
  const { location, address, city, country, state, Zipcode, latitude, longitude, publish, parkingtype, status, operator, network, category, evses, qty, createdat } = data
  const coordinates = { 'latitude': latitude, 'longitude': longitude }
  const operatorValue = { 'name': operator }
  let publistvalue = publish == "true" ? true : false
  const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + UPDATE_EVSE_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "id": sid,
        "name": location.trim(),
        'address': address,
        'city': city,
        "state": state,
        "country": country,
        "postal_code": Zipcode,
        "coordinates": coordinates,
        "publish": true,
        "role": role,
        "parking_type": parkingtype,
        "status": status,
        "evses": evses,
        "amenities": amenity,
        "operator": operatorValue,
        "location_type": network,
        "location_sub_type": category,
        "charger_qty": qty,
        "createdat": createdat,
        "organisation": organisation

      })
    });
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'UPDATE_STATION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const bootNotificationAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(COMMAND_URL + LOAD_BOOTNOTIFICATION_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'BOOT_NOTIFY', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const activateStation = (stationId) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + ACTIVATE_STATION + "?stationid=" + stationId, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'ACTIVATE_STATION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const deactivateStation = (stationId) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + DEACTIVATE_STATION + "?stationid=" + stationId, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'DEACTIVATE_STATION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const deleteStationApi = (stationId) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + STATION_DELETE_API + "?stationid=" + stationId, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'STATION_DELETE_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchLiveChargingAction = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(CHARGING_SERVICE_URL + FETCH_LIVE_CHARGING_API + "?operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LIVE_CHARGING', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const allParkingTypeAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_ALL_PARKING_TYPE_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_ALL_PARKING_TYPE', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const allConnectorTypeAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_ALL_CONNECTOR_TYPE_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_ALL_CONNECTOR_TYPE', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const allPowerRangeAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_ALL_POWER_RANGE_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_ALL_POWER_RANGE', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const allChargerStatusAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_ALL_CHARGER_STATUS_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_ALL_CHARGER_STATUS', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const allCustomerBookingList = (currentPage, pageSize) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_CUSTOMER_BOOKING_DATA + "?operator=" + operator + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_CUSTOMER_ALL_BOOKING_DATA', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchAllCustomerBookingCardCountAction = (currentPage, pageSize) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_CUSTOMER_BOOKING_DATA + "?operator=" + operator + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("FETCH_ALL_CUSTOMER_BOOKING_CARD_COUNT_REDUCER", data)
      dispatch({ type: 'FETCH_ALL_CUSTOMER_BOOKING_CARD_COUNT_REDUCER', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchChargerSlots = (evse_id) => {
  // console.log(evse_id)
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_CHARGER_BOOKING_SLOTS + "?evse_id=" + evse_id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_CHARGER_BOOKING_SLOTS', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const saveRoamingStation = (list) => {
  var arr = [];
  for (var index in list) {
    // console.log(index, list[index].stationid)
    arr.push(list[index].stationid)
  }
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + SAVE_ROAMING_STATIONS_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "ids": arr,
      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'SAVE_ROAMING_STATIONS', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const saveChargerMasterSlots = (data, stationid, selectedEvseIdOperator) => {
  // console.log("data,stationid",data,stationid)
  const { regular_hours, evse_id } = data
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  // const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + SAVE_CHARGER_MASTER_SLOTS, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "role": role,
        "operator": selectedEvseIdOperator,
        "stationid": stationid,
        'evse_id': evse_id,
        'regular_hours': regular_hours,
      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'SAVE_CHARGER_BOOKING_SLOTS', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const saveStationV2EvseAction = (data, countryCode) => {
  // console.log("countryCode", countryCode);
  const { location, address, city, free_charging, description, state, country, Zipcode, network, category, latitude, longitude, parkingtype, evses, connectors, prices, amenity, operator, ocpi, qrcode } = data
  const { token, organisation, userid, role } = JSON.parse(localStorage.getItem('user'));
  // console.log("data from evse statiopn::::::::::::::::",data)
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + CREATE_NEW_STATION_V2_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "location": location.trim(),
        'address': address,
        'city': city,
        "state": state,
        "country": country,
        "postal_code": Zipcode,
        "location_type": network,
        "location_sub_type": category,
        "latitude": latitude,
        "longitude": longitude,
        "parking_type": parkingtype,
        "evses": evses,
        "connectors": connectors,
        "prices": prices,
        "created_by": userid,
        "amenities": amenity,
        "operator": operator,
        "role": role,
        "organisation": organisation,
        "ocpi": ocpi,
        "qrcode": qrcode,
        "country_code": countryCode,
        //this is free charging and description key and value
        "free_charging": free_charging,
        "description": description,
      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'SAVE_V2_STATION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchStationV2EvseAction = (stationid) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_SINGLE_EVSE_V2_API + "?stationid=" + stationid, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_EVSE_SINGLE', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const updateStationV2EvseAction = (data, stationid, selectedAmenities, countryCode) => {
  // console.log("data from evse action",data)
  const { created_by, organisation, role, location, description, free_charging, address, city, state, country, Zipcode, network, category, latitude, longitude, parkingtype, evses, connectors, prices, amenity, operator, createdat, ocpi, qrcode } = data
  const { token } = JSON.parse(localStorage.getItem('user'));

  // console.log("data from evse station::::::::::::::::",selectedAmenities)
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + UPDATE_NEW_STATION_V2_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "id": stationid,
        "location": location.trim(),
        'address': address,
        'city': city,
        "state": state,
        "country": country,
        "postal_code": Zipcode,
        "location_type": network,
        "location_sub_type": category,
        "latitude": latitude,
        "longitude": longitude,
        "parking_type": parkingtype,
        "evses": evses,
        "connectors": connectors,
        "prices": prices,
        "amenities": selectedAmenities,
        "operator": operator,
        "role": role,
        "organisation": organisation,
        "created_by": created_by,
        "createdat": createdat,
        "ocpi": ocpi,
        "qrcode": qrcode,
        "country_code": countryCode,
        //this is free charging and description key and value
        "free_charging": free_charging,
        "description": description,

      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'UPDATE_V2_STATION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchChargingGraphAction = (stationid) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(CHARGING_SERVICE_URL + FETCH_LIVE_CHARGING_GRAPH_API + "?operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_CHARGING_GRAPH', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchEVSEPercentageChangeAction = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + EVSE_PERCENTAGE_CHANGE_API + "?role=" + role + "&organisation=" + organisation, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'EVSE_PERCENTAGE_CHANGE', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchTimeMasterListAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + TIME_MASTER_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'TIME_MASTER', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchaAllDaysListAction = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + ALL_DAYS_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_ALL_DAY_MASTER_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const saveConfigured = (data) => {
  const { email, retries, retryInterval, organisation } = data
  // console.log("org", organisation)
  const { token, name, userid } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + SAVE_CONFIGURED_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "name": name,
        "userid": userid,
        "email": email,
        "attempts": retries,
        "duration": retryInterval,
        "organisation": organisation
      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'SAVE_CONFIGURED_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const saveStationGroup = (form_data, customerGroupName, stationGroupName) => {
  // console.log(data,customerGroupName,stationGroupName)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + SAVE_STATION_GROUP_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "station_group_name": stationGroupName,
        "customer_group": customerGroupName,
        "station_list": form_data,
        "role": role,
        "organisation": organisation
      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'SAVE_STATION_GROUP_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const updateStationGroupAPI = (form_data, station_group_name, customerGroupName) => {
  // console.log(data,customerGroupName,station_group_name)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + UPDATE_STATION_GROUP_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "station_group_name": station_group_name,
        "customer_group": customerGroupName,
        "station_list": form_data,
        "role": role,
        "organisation": organisation
      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'UPDATE_STATION_GROUP', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchAllStationGroup = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_ALL_STATION_GROUP_API + "?role=" + role + "&organisation=" + organisation, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_ALL_STATION_GROUP_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchSingleStationGroup = (id) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_SINGLE_STATION_GROUP_API + "?id=" + id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_SINGLE_STATION_GROUP_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const activateStationGroup = (id) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + ACTIVATE_STATION_GROUP_API + "?id=" + id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'ACTIVATE_STATION_GROUP', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const deactivateStationGroup = (id) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + DEACTIVATE_STATION_GROUP_API + "?id=" + id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'DEACTIVATE_STATION_GROUP', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const saveStationGroupWithTariff = (form_data, tariffName, stationGroupName) => {
  // console.log(data,customerGroupName,stationGroupName)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + SAVE_STATION_GROUP_WITH_TARIFF_API, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "tariff_station_group_name": stationGroupName,
        "tariff": tariffName,
        "station_list": form_data,
        "role": role,
        "organisation": organisation
      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'SAVE_STATION_GROUP_WITH_TARIFF', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const updateStationGroupWithTariffAPI = (form_data, stationGroupName, tariffName) => {
  // console.log(data,customerGroupName,stationGroupName)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + UPDATE_STATION_GROUP_WITH_TARIFF, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "tariff_station_group_name": stationGroupName,
        "tariff": tariffName,
        "station_list": form_data,
        "role": role,
        "organisation": organisation
      })
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'UPDATE_STATION_GROUP_WITH_TARIFF', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const activateStationGroupWithTariff = (id) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + ACTIVATE_STATION_GROUP_WITH_TARIFF_API + "?id=" + id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'ACTIVATE_STATION_GROUP_WITH_TARIFF', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const deactivateStationGroupWithTariff = (id) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + DEACTIVATE_STATION_GROUP_WITH_TARIFF_API + "?id=" + id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'DEACTIVATE_STATION_GROUP_WITH_TARIFF', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchAllStationGroupWithTariff = () => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_ALL_STATION_GROUP_WITH_TARIFF + "?role=" + role + "&organisation=" + organisation, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_ALL_STATION_GROUP_WITH_TARIFF', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchSingleStationGroupWithTariff = (id) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + FETCH_SINGLE_STATION_GROUP_WITH_TARIFF + "?id=" + id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_SINGLE_STATION_GROUP_WITH_TARIFF', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const cacheRefreshApi = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'LOAD_EVSE', payload: '' })
    const data = await ApiHandler(STATION_URL + CLEARED_CACHE_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'CLEARED_CACHE_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}
