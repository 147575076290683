const initState = {
    isLoading: false,
    fetch: '',
    all: '',
    releasetransaction: '',
    chargerReport: '',
    percentageChange: '',
    revenueSharingList: '',
    error: '',
    latestTransaction: '',
    plugUnplug: '',
    fetchReportCsv: '',
    fetchMeterDataByID: '',
    latestFailedTransaction: '',
    latestSuccessTransaction: '',
    fetchSuccessTransaction: '',
    fetchAllSuccessTransaction: '',
    fetchFailedTransaction: '',
    fetchAllFailedTransaction: '',
    fetchSingleReportRfid: '',
    fetchMyReportCount: '',
    fetchSingleRfidChargingHistory: '',
    fetchRfidReport: '',
    fetchRfidLatestTransactionReport: '',
    fetchAllChargerReport: '',
    fetchAllPlugUnplug: '',
    fetchAllRevenueSharingList: '',
}

const reportReducer = (state = initState, action) => {
    switch (action.type) {
        case 'REPORT_LOADING':
            return { ...state, isLoading: true }
        case 'LOAD_DAIL_REPORT':
            return { ...state, fetch: action.payload, isLoading: false };
        case 'LOAD_ALL_REPORT':
            return { ...state, all: action.payload, isLoading: false };
        case 'FETCH_REPORT_CSV':
            return { ...state, fetchReportCsv: action.payload, isLoading: false };
        case 'RELEASE_TRANSACTION':
            return { ...state, releasetransaction: action.payload, isLoading: false };
        case 'LOAD_CHARGER_REPORT_API':
            return { ...state, chargerReport: action.payload, isLoading: false };
        case 'REPORT_PERCENTAGE_CHANGE':
            return { ...state, percentageChange: action.payload, isLoading: false };
        case 'REVENUE_SHARING_LIST':
            return { ...state, revenueSharingList: action.payload, isLoading: false };
        case 'REPORT_ERROR':
            return { ...state, error: action.payload, isLoading: false };
        case 'GET_PLUG_UNPLUG_REPORT':
            return { ...state, plugUnplug: action.payload, isLoading: false };
        case 'TOP10_REVENUE_SHARING_LIST':
            return { ...state, latestTransaction: action.payload, isLoading: false };
        case 'FETCH_METER_DATA_BY_ID':
            return { ...state, fetchMeterDataByID: action.payload, isLoading: false };
        case 'TOP10_FAILED_TRANSACTION_LIST':
            return { ...state, latestFailedTransaction: action.payload, isLoading: false };
        case 'TOP10_SUCCESS_TRANSACTION_LIST':
            return { ...state, latestSuccessTransaction: action.payload, isLoading: false };
        case 'FETCH_SUCCESS_TRANSACTION_LIST':
            return { ...state, fetchSuccessTransaction: action.payload, isLoading: false };
        case 'FETCH_All_SUCCESS_TRANSACTION_LIST':
            return { ...state, fetchAllSuccessTransaction: action.payload, isLoading: false };
        case 'FETCH_FAILED_TRANSACTION':
            return { ...state, fetchFailedTransaction: action.payload, isLoading: false };
        case 'FETCH_ALL_FAILED_TRANSACTION':
            return { ...state, fetchAllFailedTransaction: action.payload, isLoading: false };
        case 'FETCH_SINGLE_REPORT_RFID_API':
            return { ...state, fetchSingleReportRfid: action.payload, isLoading: false };
        case 'FETCH_MY_REPORT_CARD_COUNT':
            return { ...state, fetchMyReportCount: action.payload, isLoading: false };
        case 'FETCH_RFID_CHARGING_HISTORY':
            return { ...state, fetchSingleRfidChargingHistory: action.payload, isLoading: false };
        case 'FETCH_RFID_REPORT_REDUCER':
            return { ...state, fetchRfidReport: action.payload, isLoading: false };
        case 'FETCH_RFID_LATEST_TRANSACTION_REPORT_REDUCER':
            return { ...state, fetchRfidLatestTransactionReport: action.payload, isLoading: false };
        case 'FETCH_ALL_CHARGER__REPORT_DATA_REDUCER':
            return { ...state, fetchAllChargerReport: action.payload, isLoading: false };
        case 'FETCH_ALL_PLUG_UNPLUG_DATA_REDUCER':
            return { ...state, fetchAllPlugUnplug: action.payload, isLoading: false };
        case 'FETCH_ALL_REVENUE_SHARING_LIST_REDUCER':
            return { ...state, fetchAllRevenueSharingList: action.payload, isLoading: false };
        default:
            return state;
    }
}
export default reportReducer;