import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../../breadcrums";
import { singleRoamingPartner } from "../../../../../store/actions/ocpiAction";
import ReactJson from 'react-json-view';

function ViewSinglePartner(props) {
    const { party_id } = useParams()
    useEffect(() => {
        props.getSinglerData(party_id);
    }, []);
    const data = props.fetchSingleDetails && props.fetchSingleDetails.data
    const businessName = data && data.roles?.[0]?.business_details.name;
    const partyId = data && data.roles?.[0]?.party_id;
    const countrycode = data && data.roles?.[0]?.country_code;
    const version = data && data.endpoint_details.version;
    const endpoints = data && data.endpoint_details.endpoints;

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row>
                <Col className="mt-4">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}> Partner Details </span>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <Card className="tariffcard">
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">Partner Name</label>
                                        <input className="input gray-border" type="text" name="partner_name" id="floatingInputGridName" placeholder="Firstname" defaultValue={businessName} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridPID">Party ID</label>
                                        <input className="input gray-border" type="text" name="party_id" id="floatingInputGridparty_id" placeholder="Party ID" defaultValue={partyId} disabled />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">

                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridCC">Country Code</label>
                                        <input className="input gray-border" type="text" name="country_code" id="floatingInputGridcountry_code" placeholder="Country Code" defaultValue={countrycode} disabled />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div>
                                        <label htmlFor="floatingInputGridName">URL</label>
                                        <input className="input gray-border" type="text" name="url" id="floatingInputGridURL" placeholder="URL" defaultValue={data.url} disabled />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="">
                                    <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 18 }}>Endpoint Version - {version} </span>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="">
                                    <span style={{ color: "var(--text-primary)", fontWeight: "bold", fontSize: 18 }}>Endpoint Details  </span>
                                    <ReactJson src={endpoints} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    )

}


const mapStateToProps = function (state) {
    return {
        fetchSingleDetails: state.ocpi.fetchsinglepartner,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSinglerData: (party_id) => dispatch(singleRoamingPartner(party_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewSinglePartner));