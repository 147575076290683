import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect, useMemo } from "react";
import PureBreadcrumbs from "../breadcrums";
import { connect } from "react-redux";
import {
  getAllFleetName,
  getHeavyVehicleAction,
  getLightVehicleAction,
  getFleetInsights,
  fleetLiveCharging,
  fetchVehicleData,
} from "../../../store/actions/fleetAction";
import MapComponent from "./map";
import "../reacttable/reactTable.css";
import Table from "../reacttable/vehicleAndDriverTable";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useTranslation } from "react-i18next";

function FleetCard(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.all();
    props.all_Light_Vehicle();
    props.all_fleet_name();
    props.vehicle_data();
  }, []);

  const vehicleData = props.fleet_vehicle_data && props.fleet_vehicle_data.data;
  const totalVehicle = props.fleet_insight && props.fleet_insight.totalvehicle;
  const availableVehicle = props.fleet_insight && props.fleet_insight.available;
  const livechargingVehicle = props.fleet_insight && props.fleet_insight.livecharging;
  const maintenanceVehicle = props.fleet_insight && props.fleet_insight.maintainance;
  // console.log("vehicle data",vehicleData);

  const dataList = props.list && props.list
  // console.log(dataList);

  const firstRecord = props.fleet_list.data && props.fleet_list.data.map(e => e.ownername)
  const firstid = firstRecord && firstRecord[0]
  // console.log(firstid)

  useEffect(() => {
    if (firstid) {
      // props.fleet_insights(firstid);
      props.charging_list(firstid);
    }
  }, [firstid]);

  // const id = useRef(null);
  // const [timer, setTimer] = useState(360);
  // const clear = () => {
  //   window.location.reload(false);
  // };
  // useEffect(() => {
  //   id.current = window.setInterval(() => {
  //     setTimer((time) => time - 1);
  //   }, 1000);
  //   return () => clear();
  // }, []);

  // useEffect(() => {
  //   if (timer === 0) {
  //     window.location.reload(false);
  //     setTimer(360);
  //   }
  // }, [timer]);

  const handleAddClick = () => {
    window.location.reload(false);
  };

  const handleChange = (e) => {
    let fleet = e.target.value;
    if (e.target.value !== "" && e.target.value !== undefined) {
      // props.fleet_insights(fleet);
      props.charging_list(fleet);
    }
  };

  const driverData = props.fleet_insight && props.fleet_insight.vdata;

  const vehicleDataColumn = useMemo(
    () => [
      {
        Header: "Driver Name",
        accessor: (row) => `${row.firstname} ${row.lastname}`
      },
      { Header: "Vehicle Brand", accessor: "make" },
      { Header: "Vehicle Model", accessor: "model" },
      { Header: "Vehicle Number", accessor: "vin" },
    ],
    []
  );


  const driverDatacolumns = useMemo(
    () => [
      { Header: "Driver Name", accessor: "drivername" },
      { Header: "Vehicle Number", accessor: "vin" },
      { Header: "Vehicle Model", accessor: "v_model" },
      {
        Header: "Vehicle Speed",
        accessor: "speed",
        Cell: (speed) => {
          return (
            <div>
              {speed.value > 60 ? (
                <div>
                  <span>{speed.value}</span>&nbsp;
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/fleet/overspeeding.svg"
                    }
                    style={{ width: "28px" }}
                    alt="OverSpeeding"
                  />
                </div>
              ) : (
                speed.value
              )}
            </div>
          );
        },
      },
    ],
    []
  );
  const chargingData = props.fleet_charging && props.fleet_charging.data;
  // console.log("chargingData",chargingData)
  const chargingDatacolumns = useMemo(
    () => [
      { Header: "Driver Name", accessor: "customer_name" },
      { Header: "EVSE ID", accessor: "evse_id" },
      { Header: "Unit", accessor: "kwh_unit" },
      { Header: "Units", accessor: "kwh" },
      { Header: "Consume Time", accessor: "consume_time" },
      { Header: "Total Amount", accessor: "total_amount" },
      {
        Header: "State of Charge",
        accessor: "soc",
        Cell: (soc) => {
          // console.log(soc);
          return (
            <div>
              {soc.value < 25 ? (
                <ProgressBar
                  variant="danger"
                  animated
                  now={soc.value}
                  label={`${soc.value}%`}
                />
              ) : (
                <ProgressBar
                  variant="success"
                  animated
                  now={soc.value}
                  label={`${soc.value}%`}
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br></br>

      <Row>
        <Col xs={12} md={12}>
          <Card className="customercard">
            <Row>
              <Col xs={12} md={6}>
                <img
                  className="fleet-ico"
                  src={"/images/fleet/fleet.svg"}
                  alt=""
                />   {t("Select Fleet")} :&nbsp;
                <select
                  className={`fleet-select form-control d-inline-block`}
                  name="fleet_name"
                  onChange={handleChange}
                  id="floatingSelectGridU"
                >
                  {props.fleet_list.data &&
                    props.fleet_list.data.map((item, index) => (
                      <option key={index} value={item.ownername}>
                        {item.ownername}
                      </option>
                    ))}
                </select>
              </Col>
              <Col xs={12} md={6}>
                <div className="text-right">
                  &nbsp;&nbsp;
                  <a href="##"
                    className="refresh"
                    style={{
                      fontSize: "13px",
                      color: "var(--button-color)",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={handleAddClick}
                  >
                    {t("Refresh")} &nbsp;
                    <img
                      style={{ height: "16px" }}
                      src={
                        process.env.PUBLIC_URL + "/images/insight/refresh.svg"
                      }
                      alt=""
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL VEHICLES")}</Card.Text>
              <Card.Title>
                <b>
                  {totalVehicle ?
                    totalVehicle.length
                    : 0
                  }
                </b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/fleet/car1blue.svg"}
                    className="fleet-ico1"
                    alt=""
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>
                {t("RUNNING VEHICLES")}
              </Card.Text>
              <Card.Title>
                <b>
                  {" "}
                  {availableVehicle ?
                    availableVehicle.length
                    : 0
                  }
                </b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/fleet/car2green.svg"}
                    className="fleet-ico1"
                    alt=""
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>
                {t("ONGOING CHARGING")}
              </Card.Text>
              <Card.Title>
                <b>
                  {" "}
                  {livechargingVehicle ?
                    livechargingVehicle.length
                    : 0
                  }
                </b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/fleet/csblue.svg"}
                    className="fleet-ico1"
                    alt=""
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("FAULTY VEHICLES")}</Card.Text>
              <Card.Title>
                <b>
                  {maintenanceVehicle
                    ? maintenanceVehicle.length
                    : 0}
                </b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/fleet/faultycar.svg"}
                    className="fleet-ico1"
                    alt=""
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
      </Row>

      <Row className="mt-3 mb-4">
        <Col lg={5} className=" pb-5">
          <div className="nav nav-pills ">
            <div className="nav-item">
              <input type="radio" name="fleet-tabs" id="tab-1" defaultChecked className="tab-switch" />
              <label htmlFor="tab-1" className="tab-label">{t("Live View")}</label>
              <div className="tab-content">
                <Card className="customercard" >
                  <div className="table-header">{t("Vehicle & Driver Details")}</div>
                  <div>
                    {props.loading ? <h3 style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "250px",
                    }}>Loading data...</h3> :
                      driverData && driverData.length > 0 ? (
                        <div>
                          {" "}
                          <Table data={driverData} columns={driverDatacolumns}></Table>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "6rem"
                          }}
                        >
                          <h3 className="loader">{t("No data found")}</h3>
                        </div>
                      )}
                  </div>
                </Card>
              </div>
            </div>
            <div className="nav-item">
              <input type="radio" name="fleet-tabs" id="tab-2" className="tab-switch" />
              <label htmlFor="tab-2" className="tab-label">{t("Live charging")}</label>
              <div className="tab-content p-0">
                <Card className="customercard p-0">
                  <div className="table-header">{t("Live Charging Data")}</div>
                  <div className="mb-3">
                    {props.loading ? <h3 style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "250px",
                    }}>Loading data...</h3> :
                      chargingData && chargingData.length > 0 ? (
                        <div>
                          {" "}
                          <Table
                            data={chargingData}
                            columns={chargingDatacolumns}
                          ></Table>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "6rem",
                          }}
                        >
                          <h3 className="loader">{t("No data found")}</h3>
                        </div>
                      )}
                  </div>
                </Card>
              </div>
            </div>
            <div className="nav-item">
              <input type="radio" name="fleet-tabs" id="tab-3" className="tab-switch" />
              <label htmlFor="tab-3" className="tab-label">{t("Vehicles")}</label>
              <div className="tab-content">
                <Card className="customercard" >
                  <div className="table-header">{t("Vehicle Details")}</div>
                  <div>
                    {props.loading ? <h3 style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "250px",
                    }}>Loading data...</h3> :
                      vehicleData && vehicleData.length > 0 ? (
                        <div>
                          {" "}
                          <Table data={vehicleData} columns={vehicleDataColumn}></Table>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "6rem",
                          }}
                        >
                          <h3 className="loader">{t("No data found")}</h3>
                        </div>
                      )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={7}>
          <br />
          <Card className="customercard map">
            <div className="table-header">{t("Charger & Vehicle Live Tracking")}</div>
            <Card.Body>
              <MapComponent />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <Row className="mt-3">
        <Col xs={12} md={6}>
          <Card className="customercard" style={{marginBottom:"20px"}}>
            <div className="table-header">Vehicle & Driver Details</div>
            <div>
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
              }}>Loading data...</h3> :
                driverData && driverData.length > 0 ? (
                  <div>
                    {" "}
                    <Table data={driverData} columns={driverDatacolumns}></Table>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h3 className="loader">No data found</h3>
                  </div>
                )}
            </div>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card className="customercard">
            <div className="table-header">Live Charging Data</div>
            <div>
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
              }}>Loading data...</h3> :
                chargingData && chargingData.length > 0 ? (
                  <div>
                    {" "}
                    <Table
                      data={chargingData}
                      columns={chargingDatacolumns}
                    ></Table>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h3 className="loader">No data found</h3>
                  </div>
                )}
            </div>
          </Card>
        </Col>
      </Row> */}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.fleet.isFleetLoading,
    list: state.fleet.heavy,
    h_list: state.fleet.light_vehicle,
    fleet_list: state.fleet.all_fleet_name,

    fleet_insight: state.fleet.fleet_insights,
    fleet_charging: state.fleet.fleet_livecharging,
    fleet_vehicle_data: state.fleet.fleet_vehicle,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(getHeavyVehicleAction()),
    all_Light_Vehicle: () => dispatch(getLightVehicleAction()),
    all_fleet_name: () => dispatch(getAllFleetName()),

    fleet_insights: (fleet) => dispatch(getFleetInsights(fleet)),
    charging_list: (fleet) => dispatch(fleetLiveCharging(fleet)),
    vehicle_data: () => dispatch(fetchVehicleData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FleetCard);
