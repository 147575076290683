import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Container, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Table from "../../reacttable/table";
import { fetchRfidList } from "../../../../store/actions/customerActions";
import PureBreadcrumbs from "../../breadcrums";
import { FaEye } from "react-icons/fa";
import { fetchRfidLatestTransactionReportAction, fetchRfidReportAction } from "../../../../store/actions/reportActions";
import ReactSelect from "react-select";
import { allOrgTariffAction } from "../../../../store/actions/organisationAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import RfidReportPdf from "./rfidReportPdf";

function RfidReport(props) {
    const { t } = useTranslation();
    const oneWeekInMilliseconds = 6 * 24 * 60 * 60 * 1000;
    const currentDate = new Date();
    const tomorrowDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    // First table state
    const [org, setOrg] = useState(""); // For the first API call
    const [startDate, setStartDate] = useState(new Date(currentDate.getTime() - oneWeekInMilliseconds)); // First table start date
    const [endDate, setEndDate] = useState(tomorrowDate); // First table end date

    // Second table state (new)
    const [secondOrg, setSecondOrg] = useState(""); // For the second API call
    const [secondStartDate, setSecondStartDate] = useState(new Date(currentDate.getTime() - oneWeekInMilliseconds)); // Second table start date
    const [secondEndDate, setSecondEndDate] = useState(tomorrowDate); // Second table end date

    // Form input state
    const [selectedOrg, setSelectedOrg] = useState("");
    const [selectedStartDate, setSelectedStartDate] = useState(new Date(currentDate.getTime() - oneWeekInMilliseconds));
    const [selectedEndDate, setSelectedEndDate] = useState(tomorrowDate);

    // Second table form input state (new)
    const [selectedSecondOrg, setSelectedSecondOrg] = useState("");
    const [selectedSecondStartDate, setSelectedSecondStartDate] = useState(new Date(currentDate.getTime() - oneWeekInMilliseconds));
    const [selectedSecondEndDate, setSelectedSecondEndDate] = useState(tomorrowDate);


    const { role } = JSON.parse(localStorage.getItem('user'));
    const { loading, all, orgdata, latestRfidTransactionRes } = props;
    const data = all?.data;
    const allLatestDetails = latestRfidTransactionRes?.data
    const firstRecord = orgdata?.organisations?.[0] || {};
    const { name = null } = firstRecord;
    // console.log("data", data)
    // Fetch organizations list on component load
    useEffect(() => {
        props.getOrg();
    }, []);

    // Set the default org from the list on initial load
    useEffect(() => {
        if (name) {
            setOrg(name);
            setSelectedOrg(name); // Set the form input to match
            setSecondOrg(name); // Set default org for second table
            setSelectedSecondOrg(name);
        }
    }, [name]);

    // Fetch the first table data
    useEffect(() => {
        if (org && startDate && endDate) {
            props.getRfidReport(org, startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10));
        }
    }, [org, startDate, endDate]);

    // Fetch the second table data (new)
    useEffect(() => {
        if (secondOrg && secondStartDate && secondEndDate) {
            props.getRfidLatestTransactionReportApi(secondOrg, secondStartDate.toISOString().substr(0, 10), secondEndDate.toISOString().substr(0, 10));
        }
    }, [secondOrg, secondStartDate, secondEndDate]);

    const options = useMemo(() => {
        return orgdata?.organisations?.map(item => ({
            value: item.name,
            label: item.name,
        })) || [];
    }, [orgdata]);

    const columns = useMemo(
        () => [
            { Header: "Rfid", accessor: "idtag" },
            { Header: "Organisation", accessor: "operator" },
            {
                Header: "Is Free Charging?",
                accessor: "rfid_free_charging",
                Cell: ({ value }) => (
                    <span className={value ? "text-success" : "text-danger"}>
                        {value ? "Yes" : "No"}
                    </span>
                ),
            },
            // {
            //     Header: "Charging Date",
            //     accessor: (d) => moment(d.start_date).format("DD-MM-YYYY")
            // },
            {
                Header: "Amount",
                accessor: "rfid_amount_recharge"
            },
            {
                Header: "Remaining Amount",
                accessor: "rfid_wallet_amount",
                Cell: ({ value }) => (
                    <span >
                        {value.toFixed(2)}
                    </span>
                ),
            },
            {
                Header: "kWh",
                accessor: "rfid_kwh_recharge",
            },
            {
                Header: "Remaining kWh",
                accessor: "rfid_wallet_kwh",
                Cell: ({ value }) => (
                    <span >
                        {value.toFixed(2)}
                    </span>
                ),
            },
            {
                Header: "Time (in Hrs)",
                accessor: "rfid_time_recharge"
            },
            {
                Header: "Remaining time (in Min)",
                accessor: "rfid_wallet_time",
                Cell: ({ value }) => (
                    <span >
                        {value.toFixed(2)}
                    </span>
                ),
            },
            {
                Header: "Actions",
                id: "action_idtag",
                accessor: "idtag",
                Cell: ({ value }) => (
                    <a href={`/dashboard/rfid_report/viewReportSingleRfid/${value}`} title="View">
                        <FaEye />
                    </a>
                ),
            }
        ],
        []
    );

    const secondColumns = [...columns];

    const headers = [
        { label: "RFID", key: "idtag" },
        { label: "Organisation", key: "operator" },
        { label: "Free charging ?", key: "rfid_free_charging" },
        // { label: "Start date", key: "start_date" },
        { label: "Amount", key: "rfid_amount_recharge" },
        { label: "Remaining Amount", key: "rfid_wallet_amount" },
        { label: "kWh", key: "rfid_kwh_recharge" },
        { label: "Remaining kWh", key: "rfid_wallet_kwh" },
        { label: "Time (in Hrs)", key: "rfid_time_recharge" },
        { label: "Remaining time (in Min)", key: "rfid_wallet_time" },
    ];

    const handleOrgChange = (e) => {
        setSelectedOrg(e.value);
    };

    const handleSecondOrgChange = (e) => {
        setSelectedSecondOrg(e.value);
    };

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleSecondStartDateChange = (date) => {
        setSelectedSecondStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    const handleSecondEndDateChange = (date) => {
        setSelectedSecondEndDate(date);
    };

    // Load new data when 'Go' is clicked for the first table
    const loadNewData = () => {
        setOrg(selectedOrg);
        setStartDate(selectedStartDate);
        setEndDate(selectedEndDate);
    };

    // Load new data when 'Go' is clicked for the second table
    const loadNewSecondData = () => {
        setSecondOrg(selectedSecondOrg);
        setSecondStartDate(selectedSecondStartDate);
        setSecondEndDate(selectedSecondEndDate);
    };

    return (
        <Container fluid>
            <PureBreadcrumbs />
            {/* RFID Latest Transaction Details */}
            <Row className="mt-4">
                <Col>
                    <Card className="customercard">
                        <Card.Header>
                            <Row>
                                <Col md={2} sm={12}>
                                    {t("RFID Partner Report")}

                                </Col>
                                <Col md={10} sm={12}>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <ReactSelect
                                            options={options || []}
                                            placeholder={name}
                                            value={options?.find(option => option?.value === selectedSecondOrg)}
                                            onChange={handleSecondOrgChange}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: 'var(--select-control-background-color-white)', // White
                                                    borderColor: state.isFocused ? 'var(--select-control-border-color-gray)' : 'var(--select-control-border-color-gray)', // Gray
                                                    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                                    color: 'var(--select-control-text-color-black)', // White text color for the control
                                                    // height: 'calc(1.5em + 0.75rem + 2px)',
                                                    // minHeight: 'calc(1.5em + 0.75rem + 2px)',
                                                    '&:hover': {
                                                        borderColor: 'var(--select-control-hover-border-color-gray)', // Gray
                                                    },
                                                }),
                                                valueContainer: (baseStyles) => ({
                                                    ...baseStyles,
                                                    // height: 'calc(1.5em + 0.75rem + 2px)',
                                                    padding: '0 0.75rem',
                                                    color: 'var(--select-control-text-color-black)', // White text color
                                                    width: '11rem',
                                                }),
                                                placeholder: (baseStyles) => ({
                                                    ...baseStyles,
                                                    whiteSpace: 'nowrap',
                                                    color: 'var(--select-placeholder-color-gray)', // Gray placeholder color
                                                }),
                                                input: (base) => ({
                                                    ...base,
                                                    color: 'var(--select-control-text-color-black)', // White text color
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                    '& input': {
                                                        border: 'none !important',
                                                        color: 'var(--select-control-text-color-black)', // White text color
                                                    },
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: 'var(--select-menu-background-color-black)', // Black
                                                    color: 'var(--select-menu-text-color-white)', // White text color
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: state.isFocused
                                                        ? 'var(--select-option-hover-background-color-gray)' // Gray on hover
                                                        : 'var(--select-menu-background-color-black)', // Black background
                                                    color: state.isSelected
                                                        ? 'var(--select-option-selected-text-color-white)' // White if selected
                                                        : 'var(--select-menu-text-color-white)', // White otherwise
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: 'var(--select-option-hover-background-color-gray)', // Gray on hover
                                                    },
                                                }),
                                                multiValue: (base) => ({
                                                    ...base,
                                                    backgroundColor: 'var(--multi-value-background-color-gray)', // Gray
                                                }),
                                                multiValueLabel: (base) => ({
                                                    ...base,
                                                    color: 'var(--multi-value-text-color-white)', // White text color
                                                }),
                                                multiValueRemove: (base) => ({
                                                    ...base,
                                                    color: 'var(--multi-value-text-color-white)', // White text color
                                                    ':hover': {
                                                        backgroundColor: 'var(--select-option-hover-background-color-gray)', // Gray on hover
                                                        color: 'var(--multi-value-text-color-white)', // White text color
                                                    },
                                                }),
                                                clearIndicator: (base) => ({
                                                    ...base,
                                                    color: 'var(--clear-indicator-color-white)', // White
                                                    ':hover': {
                                                        color: 'var(--clear-indicator-color-white)', // White on hover
                                                    },
                                                    cursor: 'pointer'
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    color: 'var(--select-control-text-color-black)', // White text color
                                                }),
                                            }}
                                        />
                                        <div className="d-flex align-items-center ml-3">
                                            <DatePicker
                                                className="gray-border calendar"
                                                selected={selectedSecondStartDate}
                                                onChange={handleSecondStartDateChange}
                                                selectsStart
                                                startDate={selectedSecondStartDate}
                                                maxDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                            <span className="mx-2">To</span>
                                            <DatePicker
                                                className="gray-border calendar"
                                                selected={selectedSecondEndDate}
                                                onChange={handleSecondEndDateChange}
                                                selectsEnd
                                                startDate={selectedSecondStartDate}
                                                endDate={selectedSecondEndDate}
                                                minDate={selectedSecondStartDate}
                                                maxDate={tomorrowDate}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="csvbutton ml-3 ">
                                            <button className="btn btn-sm" onClick={loadNewSecondData}>
                                                <span style={{ fontSize: "16px", color: "#676767", fontWeight: "600" }}>GO</span>
                                            </button>
                                        </div>
                                        <div className="mt-1">
                                            {allLatestDetails && allLatestDetails != null ? (
                                                <CSVLink
                                                    data={allLatestDetails}
                                                    headers={headers}
                                                    filename={`${secondOrg}_${secondStartDate.toISOString().substr(0, 10)}_${secondEndDate.toISOString().substr(0, 10)}.csv`}
                                                    target="_blank"
                                                >
                                                    <i className="fas fa-2x fa-file-csv"></i>
                                                </CSVLink>
                                            ) : (
                                                <i className="fas fa-2x fa-file-csv"></i>
                                            )}
                                        </div>
                                        <div className="p-3" style={{ paddingRight: "15px" }}>
                                            <RfidReportPdf {...props} data={allLatestDetails} org={secondOrg} start={secondStartDate} end={secondEndDate}></RfidReportPdf>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border" variant="primary" />
                                </div>
                            ) : allLatestDetails && allLatestDetails.length > 0 ? (
                                <Table columns={secondColumns} data={allLatestDetails} />
                            ) : (
                                <div className="d-flex justify-content-center">
                                    <span>{t("No records found")}</span>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Rfid Report */}
            <Row className="mt-4">
                <Col>
                    <Card className="customercard">
                        <Card.Header>
                            <Row>
                                <Col md={2} sm={12}>
                                    {t("RFID Transaction Reports")}
                                </Col>
                                <Col md={10} sm={12}>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <ReactSelect
                                            options={options || []}
                                            placeholder={name}
                                            value={options.find(option => option.value === selectedOrg)}
                                            onChange={handleOrgChange}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: 'var(--select-control-background-color-white)', // White
                                                    borderColor: state.isFocused ? 'var(--select-control-border-color-gray)' : 'var(--select-control-border-color-gray)', // Gray
                                                    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                                    color: 'var(--select-control-text-color-black)', // White text color for the control
                                                    // height: 'calc(1.5em + 0.75rem + 2px)',
                                                    // minHeight: 'calc(1.5em + 0.75rem + 2px)',
                                                    '&:hover': {
                                                        borderColor: 'var(--select-control-hover-border-color-gray)', // Gray
                                                    },
                                                }),
                                                valueContainer: (baseStyles) => ({
                                                    ...baseStyles,
                                                    // height: 'calc(1.5em + 0.75rem + 2px)',
                                                    padding: '0 0.75rem',
                                                    color: 'var(--select-control-text-color-black)', // White text color
                                                    width: '11rem',
                                                }),
                                                placeholder: (baseStyles) => ({
                                                    ...baseStyles,
                                                    whiteSpace: 'nowrap',
                                                    color: 'var(--select-placeholder-color-gray)', // Gray placeholder color
                                                }),
                                                input: (base) => ({
                                                    ...base,
                                                    color: 'var(--select-control-text-color-black)', // White text color
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                    '& input': {
                                                        border: 'none !important',
                                                        color: 'var(--select-control-text-color-black)', // White text color
                                                    },
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: 'var(--select-menu-background-color-black)', // Black
                                                    color: 'var(--select-menu-text-color-white)', // White text color
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: state.isFocused
                                                        ? 'var(--select-option-hover-background-color-gray)' // Gray on hover
                                                        : 'var(--select-menu-background-color-black)', // Black background
                                                    color: state.isSelected
                                                        ? 'var(--select-option-selected-text-color-white)' // White if selected
                                                        : 'var(--select-menu-text-color-white)', // White otherwise
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: 'var(--select-option-hover-background-color-gray)', // Gray on hover
                                                    },
                                                }),
                                                multiValue: (base) => ({
                                                    ...base,
                                                    backgroundColor: 'var(--multi-value-background-color-gray)', // Gray
                                                }),
                                                multiValueLabel: (base) => ({
                                                    ...base,
                                                    color: 'var(--multi-value-text-color-white)', // White text color
                                                }),
                                                multiValueRemove: (base) => ({
                                                    ...base,
                                                    color: 'var(--multi-value-text-color-white)', // White text color
                                                    ':hover': {
                                                        backgroundColor: 'var(--select-option-hover-background-color-gray)', // Gray on hover
                                                        color: 'var(--multi-value-text-color-white)', // White text color
                                                    },
                                                }),
                                                clearIndicator: (base) => ({
                                                    ...base,
                                                    color: 'var(--clear-indicator-color-white)', // White
                                                    ':hover': {
                                                        color: 'var(--clear-indicator-color-white)', // White on hover
                                                    },
                                                    cursor: 'pointer'
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    color: 'var(--select-control-text-color-black)', // White text color
                                                }),
                                            }}
                                        />
                                        <div className="d-flex align-items-center ml-3">
                                            <DatePicker
                                                className="gray-border calendar"
                                                selected={selectedStartDate}
                                                onChange={handleStartDateChange}
                                                selectsStart
                                                startDate={selectedStartDate}
                                                maxDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                            <span className="mx-2">To</span>
                                            <DatePicker
                                                className="gray-border calendar"
                                                selected={selectedEndDate}
                                                onChange={handleEndDateChange}
                                                selectsEnd
                                                startDate={selectedStartDate}
                                                endDate={selectedEndDate}
                                                minDate={selectedStartDate}
                                                maxDate={tomorrowDate}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="csvbutton ml-3 ">
                                            <button className="btn btn-sm" onClick={loadNewData}>
                                                <span style={{ fontSize: "16px", color: "#676767", fontWeight: "600" }}>GO</span>
                                            </button>
                                        </div>
                                        <div className="mt-1">
                                            {data && data != null ? (
                                                <CSVLink
                                                    data={data}
                                                    headers={headers}
                                                    filename={`${org}_${startDate.toISOString().substr(0, 10)}_${endDate.toISOString().substr(0, 10)}.csv`}
                                                    target="_blank"
                                                >
                                                    <i className="fas fa-2x fa-file-csv"></i>
                                                </CSVLink>
                                            ) : (
                                                <i className="fas fa-2x fa-file-csv"></i>
                                            )}
                                        </div>
                                        <div className="p-3" style={{ paddingRight: "15px" }}>
                                            <RfidReportPdf {...props} data={data} org={org} start={startDate} end={endDate}></RfidReportPdf>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border" variant="primary" />
                                </div>
                            ) : data && data.length > 0 ? (
                                <Table columns={columns} data={data} />
                            ) : (
                                <div className="d-flex justify-content-center">
                                    <span>{t("No records found")}</span>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    loading: state.report.loading,
    all: state.report.fetchRfidReport,
    latestRfidTransactionRes: state.report.fetchRfidLatestTransactionReport,
    orgdata: state.operator.organisationsList
});

const mapDispatchToProps = (dispatch) => ({
    getRfidReport: (org, start, end) => dispatch(fetchRfidReportAction(org, start, end)),
    getRfidLatestTransactionReportApi: (org, start, end) => dispatch(fetchRfidLatestTransactionReportAction(org, start, end)),
    getOrg: () => dispatch(allOrgTariffAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RfidReport);
