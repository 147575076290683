import React from "react";
import { useTranslation } from "react-i18next";

export const GlobalFilter = ({ filter, setFilter }) => {
  console.log("filter: ", filter)
  const { t } = useTranslation();
  return (
    <span>
      <input
        className="SearchFilter p-3"
        placeholder={t("Search")}
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
      <i className="" style={{ cursor: "pointer", marginLeft: "-30px" }}><img className="pr-1" src={process.env.PUBLIC_URL + '/images/admin/inputSearch.svg'} style={{ height: "15px" }} ></img></i>
    </span>

  );


};

export default GlobalFilter