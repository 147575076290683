import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import ChargerReportTable from "./adminReport/chargerReportTable";
import { fetchAllChargerReportAction, fetchChargerReport } from "../../../store/actions/reportActions";
import { connect } from "react-redux";
import PureBreadcrumbs from "../breadcrums";
import { useTranslation } from "react-i18next";
import ChargerReportPdf from "./chargerReportPdf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomReportPaginationTable from "./adminReport/customReportPaginationTable";
import Table from "../reacttable/table";
import { CSVLink } from "react-csv";

function ChargerReport(props) {
    const { t } = useTranslation();
    const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const currentDate = new Date();
    const tomorrowDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    const [startDate, setStartDate] = useState(new Date(currentDate.getTime() - oneMonthInMilliseconds)); // First table start date
    const [endDate, setEndDate] = useState(tomorrowDate); // First table end date
    const [selectedStartDate, setSelectedStartDate] = useState(new Date(currentDate.getTime() - oneMonthInMilliseconds));
    const [selectedEndDate, setSelectedEndDate] = useState(tomorrowDate);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const document_count = props.charger_report && props.charger_report.document_count;
    const defaultPageSize = 10;
    const pageCount = Math.ceil(document_count / defaultPageSize)


    useEffect(() => {
        if (startDate && endDate) {
            props.getAllChargerReportDataApi(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), 1, 10000000);
            props.chargerReportApi(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), currentPage, pageSize);
        }
    }, [startDate, endDate, currentPage, pageSize]);

    const allData = props.fetchAllChargerReportRes ? props.fetchAllChargerReportRes.data : [];
    const data = props.charger_report ? props.charger_report.data : [];
    // console.log("data", data);

    const columns = useMemo(
        () => [
            {
                Header: "Evse ID",
                accessor: "evse_id",
            },
            {
                Header: "Station Name",
                accessor: "stationid",
            },
            {
                Header: "Location",
                accessor: "location",
            },
            {
                Header: "City",
                accessor: "city",
            },
            {
                Header: "State",
                accessor: "state",
            },
            {
                Header: "Total Energy",
                accessor: "energy",
            },
            {
                Header: "Total Amount",
                accessor: "total",
            },
        ],
        []
    );

    const initialVisibleColumns =
        [
            'evse_id', 'stationid', 'location', 'city', 'state',
            'total', 'energy'
        ];

    const headers = [
        { label: "Evse ID", key: "evse_id" },
        { label: "Station Name", key: "stationid" },
        { label: "Location", key: "location" },
        { label: "City", key: "city" },
        { label: "State", key: "state" },
        { label: "Total Energy", key: "energy" },
        { label: "Total Amount", key: "total" },
    ];

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    const loadNewData = () => {
        setStartDate(selectedStartDate);
        setEndDate(selectedEndDate);
    };

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row>
                <Col xs={12} md={12}>
                    <Card className="customercard mt-3">
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col md={2} className="rfid-test">
                                    {t("Charger Report")}
                                </Col>

                                <Col md={9} className="d-flex justify-content-end">
                                    <div className="d-flex align-items-center ml-3">
                                        <DatePicker
                                            className="gray-border calendar"
                                            selected={selectedStartDate}
                                            onChange={handleStartDateChange}
                                            selectsStart
                                            startDate={selectedStartDate}
                                            maxDate={new Date()}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        <span className="mx-2">To</span>
                                        <DatePicker
                                            className="gray-border calendar"
                                            selected={selectedEndDate}
                                            onChange={handleEndDateChange}
                                            selectsEnd
                                            startDate={selectedStartDate}
                                            endDate={selectedEndDate}
                                            minDate={selectedStartDate}
                                            maxDate={tomorrowDate}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                </Col>
                                <Col md={1} className="d-flex justify-content-end">
                                    <div className="csvbutton">
                                        <button className="btn btn-sm" onClick={loadNewData}>
                                            <span style={{ fontSize: "16px", color: "#676767", fontWeight: "600" }}>GO</span>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">

                                <Col md={6} className="d-flex justify-content-end">
                                    {/* Add any other content here if needed */}
                                </Col>
                                <Col md={6} className="d-flex justify-content-end align-items-center">
                                    {/* CSV Download Button */}
                                    {data && data.length > 0 ?
                                        <CSVLink
                                            data={data}
                                            headers={headers}
                                            filename={`${startDate.toISOString().substr(0, 10)}_${endDate.toISOString().substr(0, 10)}_Charger_Report.csv`}
                                            target="_blank"
                                            className="mr-3"
                                            style={{ textDecoration: 'none', "fontSize": "30px" }}
                                        >
                                            <i className="fas fa-file-csv mr-2"></i>
                                        </CSVLink>
                                        :
                                        <>
                                        </>
                                    }

                                    {/* Charger Report PDF Button */}
                                    {data && data.length > 0 ?
                                        <ChargerReportPdf
                                            {...props}
                                            allData={allData}
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                        :
                                        <>
                                        </>
                                    }
                                </Col>
                            </Row>


                        </Card.Header>
                        <>
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "250px",
                            }}>Loading data...</h3> :
                                props.charger_report && props.charger_report?.data && props.charger_report?.data?.length !== null ? (
                                    <div>
                                        {/* <Table data={data} columns={columns} ></Table> */}
                                        <CustomReportPaginationTable
                                            data={data}
                                            allData={allData}
                                            pageCount={pageCount}
                                            columns={columns}
                                            search={search}
                                            setSearch={setSearch}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageSize={pageSize}
                                            setPageSize={setPageSize}
                                            filteredData={filteredData}
                                            setFilteredData={setFilteredData}
                                            loading={props.loading}
                                            initialVisibleColumns={initialVisibleColumns}
                                            t={t}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3>{t("No Data Found")}</h3>
                                    </div>
                                )}
                        </>
                        {/* <ChargerReportTable {...props} allData={allData} /> */}
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.report.isLoading,
        fetchAllChargerReportRes: state.report.fetchAllChargerReport,
        charger_report: state.report.chargerReport,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllChargerReportDataApi: (startDate, endDate, currentPage, pageSize) => dispatch(fetchAllChargerReportAction(startDate, endDate, currentPage, pageSize)),
        chargerReportApi: (startDate, endDate, currentPage, pageSize) => dispatch(fetchChargerReport(startDate, endDate, currentPage, pageSize)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChargerReport);
